<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title class="elevation-1">
            Review Details
            <v-spacer></v-spacer>
          </v-card-title>
          <!-- <b-container v-if="report"> -->
          <div>
            <v-row class="py-2 justify-content-md-center">
              <v-col cols="12" class="text-center">
                <v-avatar v-if="ratings.image && !imagePreview" size="150">
                  <v-img :src="baseURL + ratings.image" />
                </v-avatar>
                <v-avatar>
                  <v-img
                    v-if="imagePreview"
                    :src="imagePreview"
                    width="150px"
                    height="150px"
                    class="align-center"
                    contain
                  /> </v-avatar
                ><br />
                <!-- <v-btn @click="$refs.showImage.click()"
                  >Update Image
                  <v-item-group>
                    <input
                      v-show="false"
                      label="Upload Image"
                      ref="showImage"
                      id="file"
                      type="file"
                      style="display: none"
                      @change="showImage"
                    />
                  </v-item-group> </v-btn
                ><br /> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title"> Name</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ ratings.name }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Comment</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ ratings.comment }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Rating</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{
                      parseFloat(ratings.rating)
                    }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["rateid"],
  data() {
    return {
      file: null,
      showPreview: false,
      imagePreview: null,
      rating: 4.5,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      page: 5,
      name: "",
      comment: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      ratings: [],
      data: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/topreview/get",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.rateid,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.ratings = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      this.showPreview = true;
      this.uploadImage();
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("id", this.rateid);
      formData.append("image", this.file);

      axios({
        method: "POST",
        url: "/topreview/UploadImage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 14px;
}
.item_value {
  font-size: 13px;
}
</style>